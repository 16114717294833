import { useRef, useEffect } from 'react';
import { Rect, Group, Text, Transformer } from 'react-konva';

export function Rectangle({ shapeProps, isSelected, onSelect, onChange, shapeColors, zoomLevel=100, fileName, activePage, keyId }) {
    const shapeRef = useRef();
    const trRef = useRef();

    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    function whenSelected(e, from){
        onSelect(e);
    }

    function adjustRectangleBasedOnZoomLevel(){
        let storedrect = JSON.parse(localStorage.getItem(fileName));
        if(storedrect){
            let origRectangle = storedrect[activePage][keyId];
            if(origRectangle){
                shapeProps.x = origRectangle.x * zoomLevel/100;
                shapeProps.y = origRectangle.y * zoomLevel/100;
                shapeProps.width = origRectangle.width * zoomLevel/100;
                shapeProps.height = origRectangle.height * zoomLevel/100;
            }
        }
    }
    adjustRectangleBasedOnZoomLevel();

    return (
        <>
            <Group
                id={shapeProps.id}
                x={shapeProps.x}
                y={shapeProps.y}
                width={shapeProps.width}
                height={shapeProps.height}
                onClick={e=>{whenSelected(e,"group")}}
                onTap={onSelect}
                draggable={isSelected}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                ref={shapeRef}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                }}
            >
                <Rect
                    // {...shapeProps}
                    width={shapeProps.width}
                    height={shapeProps.height}
                    {...shapeColors}
                    strokeWidth={1}
                    
                    onClick={e=>{whenSelected(e,"rect")}}
                    onTap={onSelect}
                />
                <Text
                    text={shapeProps.label}
                    fontSize={15}
                    onClick={e=>{whenSelected(e,"text")}}
                    onTap={onSelect}
                    // x={shapeProps.width > 0 ? shapeProps.x : shapeProps.x - shapeProps.width}
                    // y={(shapeProps.height > 0 ? shapeProps.y : shapeProps.y - shapeProps.height) + (shapeProps.height / 2) - 10}
                    x={0}
                    y={0}
                    width={Math.abs(shapeProps.width)}
                    height={Math.abs(shapeProps.height)}
                    padding={5}
                    align='left'
                    verticalAlign='top'
                    fill='white'
                    strokeWidth={0}
                    fontStyle='bold'
                    stroke='white'
                    shadowColor='black'
                    shadowBlur={5}
                    shadowOpacity={0.9}
                />
            </Group>
            {isSelected && (
                <Transformer
                    ref={trRef}
                    rotateEnabled={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </>
    );
};