import React from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import Home from './Home';
import Login from './Login';
import Labeller from './Labeller';
import { oktaAuthConfig, oktaSignInConfig } from './Config'; 

const oktaAuth = new OktaAuth(oktaAuthConfig);

const App = () => {
  const history = useHistory();

  const customAuthHandler = () => {
    history.push('/login');
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={oktaAuthConfig.restoreOriginalUri} 
    >
      <Switch>
        <Route path='/' exact={true} component={Home} />
        <SecureRoute path='/labeller' component={Labeller} />
        <Route path='/login' render={() => <Login config={oktaSignInConfig} />} />
        <Route path='/login/callback' component={LoginCallback} />
      </Switch>
    </Security>
  );
};
export default App;