const oktaAuthConfig = {
    // Note: If your app is configured to use the Implicit flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to add `pkce: false`
    issuer: process.env.REACT_APP_OKTA_BASE_URL+'/oauth2/default',
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/login/callback',
    restoreOriginalUri: async (oktaAuth, originalUri) => {
    // redirect with custom router
    // router.replace({
    //   path: toRelativeUrl(originalUri, baseUrl)
    // });
    }
  };
  
  const oktaSignInConfig = {
    baseUrl: process.env.REACT_APP_OKTA_BASE_URL,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/login/callback',
    authParams: {
      // If your app is configured to use the Implicit flow
      // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
      // you will need to uncomment the below line
      // pkce: false
    }
  };

  const grobidBaseUrl = process.env.REACT_APP_GROBID_WEBSERVICE_BASE_URL;
  
  export { oktaAuthConfig, oktaSignInConfig, grobidBaseUrl };