import { useState, useRef, useEffect } from 'react';
import { pdfjs, Document, Page } from 'react-pdf'
import { Stage, Layer } from 'react-konva';
import 'saxon-js';
import { useOktaAuth } from '@okta/okta-react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { Button, AppBar, Toolbar, Typography,Switch, FormGroup, FormControlLabel, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

import { Rectangle } from './Rectangle';
import { DebugBox } from './DebugBox';

import { grobidBaseUrl } from './Config';

import './App.css';
import './Paper.css';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  popover: {
    pointerEvents: 'none',
    
    
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: '400px',
    fontSize: '0.9em'
  },
}));

const urlParams = new URLSearchParams(window.location.search);
const debugMode = window.location.hostname === 'localhost' ? 1 : urlParams.get('debug');
const fileurl = urlParams.get('file');
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Labeller() {
  const classes = useStyles();

  const fileLoc = fileurl || './pdfs/pdfjspaper.pdf';
  const filename = fileLoc.split("/").slice(-1)[0].replace(".pdf", "");

  const { oktaAuth, authState } = useOktaAuth();

  const [numPages, setNumPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [selectedId, selectShape] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [stageSize, setStageSize] = useState({ width: 1000, height: 0, displayRatio: 1 });
  const [userName, setUserName] = useState(null);
  // const [userInfo, setUserInfo] = useState(null);
  // const [viewport, setViewport] = useState({});
  const [rectangles, setRectangles] = useState([]);
  // const [textData, setTextData] = useState([]);
  const [origSize, setOrigSize] = useState({ width: 0, height: 0 });
  const [newRectangle, setNewRectangle] = useState([]);
  const [captionTargetSelectionMode, setCaptionTargetSelectionMode] = useState(false);
  const [NextParagraphMode, setNextParagraphMode] = useState(false);
  const [webViewMode, setWebViewMode] = useState(false);

  const [popOverAnchorEl, setPopOverAnchorEl] = useState(null);
  const [popOverContent, setPopOverContent] = useState("");

  const pageRef = useRef();

  const labelSelectorOpen = useRef(false);

  const defaultWidth = 1000;

  useEffect(() => {
    document.addEventListener("keydown", stageKeyDown);
    return _ => {
      document.removeEventListener("keydown", stageKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      // setUserInfo(null);
    } else {
      let json = JSON.parse(localStorage["okta-token-storage"]);
      setUserName(json.accessToken.claims.sub);
      // oktaAuth.getUserInfo().then(info => {
      //   setUserInfo(info);
      // });
    }
  }, [authState, oktaAuth]);

  const logout = async () => oktaAuth.signOut();

  //#region init
  function initLabels(numPages, forceclear = false) {
    const initrectangles = [];
    for (let i = 0; i < numPages + 1; i++) {
      initrectangles.push([]);
    }
    let labelfromstorage = JSON.parse(localStorage.getItem(filename));
    if (labelfromstorage && !forceclear) {
      setRectangles(labelfromstorage);
    }
    else {
      setRectangles(initrectangles);
    }
  }
  //#endregion

  //#region events
  const handlePopoverOpen = (event) => {
    let target = event.currentTarget;
    let targetref = target.getAttribute("data-targetref");
    let targetrefdom = document.getElementById(targetref);
    if(targetrefdom){
      let popovercontent = targetrefdom.cloneNode(true);
      let a = popovercontent.querySelector("a");
      a.remove();
      setPopOverContent(popovercontent.innerHTML);
      // setPopOverContent(targetrefdom.innerHTML);
      setPopOverAnchorEl(target);
    }
  };

  const handlePopoverClose = () => {
    setPopOverAnchorEl(null);
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    initLabels(numPages);
    extractText(numPages);
    callGrobid();
  }
  function onSaveButtonClicked(e) {
    var loadingTask = pdfjs.getDocument(fileLoc);
    loadingTask.promise.then(function (pdf) {
      var canvasdiv = document.getElementById('tempCanvas');
      var totalPages = pdf.numPages
      var data = [];

      for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
        pdf.getPage(pageNumber).then(function (page) {

          var desiredWidth = 1000;
          var originalViewport = page.getViewport({ scale: 1, });
          var scale = desiredWidth / originalViewport.width;
          var viewport = page.getViewport({ scale: scale, });

          var canvas = document.createElement('canvas');
          canvasdiv.appendChild(canvas);

          // Prepare canvas using PDF page dimensions
          var context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          // Render PDF page into canvas context
          var renderContext = { canvasContext: context, viewport: viewport };

          var renderTask = page.render(renderContext);
          renderTask.promise.then(function () {
            const contentType = 'image/png';
            let datum = canvas.toDataURL(contentType);
            data.push(canvas.toDataURL(contentType, datum));
            checkFileGeneration(data);
            downloadBase64File(datum, filename + "_page" + pageNumber + ".png");
            canvas.remove();
          });
        });
      }

    }, function (reason) {
      // PDF loading error
      console.error(reason);
    });
  }
  function checkFileGeneration(data) {
    if (data.length === numPages) {
      let zip = new JSZip();
      for (let i = 0; i < data.length; i++) {
        zip.file(filename + "_page" + i + ".png", data[i], { base64: true })
      }
      zip.generateAsync({ type: "blob" })
        .then(function (content) {
          // see FileSaver.js
          saveAs(content, "example.zip");
        });
    }
  };
  function onDownloadAnnotationButtonClicked() {
    let annotationsData = JSON.parse(localStorage.getItem(filename));
    downloadObjectAsJson(annotationsData, filename);
  }
  function onPageLoadSuccess(e) {
    let desiredWidth = defaultWidth * zoomLevel / 100;
    setStageSize({ width: desiredWidth, height: (e.originalHeight * 1000 / e.originalWidth) * zoomLevel / 100 });
    setOrigSize({ width: e.originalWidth, height: e.originalHeight, displayRatio: e.originalWidth / e.originalHeight });

    // var loadingTask = pdfjs.getDocument(fileLoc);
    // loadingTask.promise.then(function (pdf) {
    //   pdf.getPage(1).then(function (page) {
    //     var originalViewport = page.getViewport({ scale: 1, });
    //     var scale = desiredWidth / originalViewport.width;
    //     var viewport = page.getViewport({ scale: scale, });
    //     setViewport(viewport);
    //   });
    // });
  }
  function onNextButtonClicked() {
    setActivePage(activePage < numPages ? activePage + 1 : numPages)
  }
  function onPrevButtonClicked() {
    setActivePage(activePage - 1 || 1)
  }
  function stageMouseDown(e) {
    checkDeselect(e);
    if (labelSelectorOpen.current) {
      let target = e.evt.target.closest("#labelSelector");
      if (!target) {
        rectangles[activePage].pop();
        hideLabelSelector();
      }
    }
    if (newRectangle.length === 0 && !labelSelectorOpen.current && !selectedId) {
      // if (newRectangle.length === 0 && e.target.attrs.className === "pageStage" && !labelSelectorOpen.current) {
      const { x, y } = e.target.getStage().getPointerPosition();
      setNewRectangle([{ x, y, width: 0, height: 0, key: "0" }]);
    }
  }
  function stageMouseMove(e) {
    if (newRectangle.length === 1) {
      const sx = newRectangle[0].x;
      const sy = newRectangle[0].y;
      const { x, y } = e.target.getStage().getPointerPosition();
      setNewRectangle([
        {
          x: sx,
          y: sy,
          width: x - sx,
          height: y - sy,
          key: "0",
          id: "rect0"
        }
      ]);
    }
  }
  function stageMouseUp(e) {
    if (newRectangle.length === 1) {
      const { x, y } = e.target.getStage().getPointerPosition();
      let sx = newRectangle[0].x;
      let sy = newRectangle[0].y;
      let w = x - sx;
      let h = y - sy;
      if (w < 0) {
        w = Math.abs(w);
        sx = x;
      }
      if (h < 0) {
        h = Math.abs(h);
        sy = y;
      }
      if (w > 50) {
        const rectToAdd = {
          x: sx,
          y: sy,
          width: w,
          height: h,
          key: rectangles[activePage].length,
          id: 'rect' + (rectangles[activePage].length),
          labeller: userName
        };
        if (NextParagraphMode) {
          rectToAdd.label = "PartialParagraph";
          let prevPage = parseInt(NextParagraphMode.split(":")[0].replace("page", ""));
          rectangles[prevPage][rectangles[prevPage].length - 1].paragraphContinuation = "page" + activePage + ":" + rectToAdd.id;
        }
        rectangles[activePage].push(rectToAdd);
        spawnNewRectangle();
        if (!NextParagraphMode) {
          showLabelSelector(rectToAdd, function (chosenlabel) {
            if (chosenlabel) {
              if (chosenlabel === "Caption") {
                setCaptionTargetSelectionMode(true);
                let warnbar = document.getElementById("warnBar");
                warnbar.innerHTML = "Please select the target label for the caption"
                warnbar.style.display = "block";
              }
              else if (chosenlabel === "PartialParagraph") {
                setNextParagraphMode("page" + activePage + ":" + rectToAdd.id);
                let warnbar = document.getElementById("warnBar");
                warnbar.innerHTML = "";
                let warnSpan = document.createElement("span");
                warnSpan.innerHTML = "Draw the label on the paragraph continuation or click done button beside if it is the end of the paragraph";
                let doneNextButton = document.createElement("button");
                doneNextButton.innerHTML = "Done";
                doneNextButton.onclick = onDoneSelectingNextParagraph;

                warnbar.append(warnSpan, doneNextButton);
                warnbar.style.display = "block";
              }

              rectToAdd.label = chosenlabel;
            }
            else {
              rectangles[activePage].pop();
              hideLabelSelector();
            }
            spawnNewRectangle();
          });
        }
      }
      else {
        spawnNewRectangle();
      }
    }
  }
  function stageKeyDown(e) {
    if (e.key === "Delete" && selectedId) {
      let tobedeletedid = parseInt(selectedId.replace("rect", ""));
      rectangles[activePage].splice(tobedeletedid, 1);
      updateRectIds();
      spawnNewRectangle();
    }
  }
  function onDoneSelectingNextParagraph(e) {
    setNextParagraphMode(false);
    let warnbar = document.getElementById("warnBar");
    warnbar.style.display = "none";
  }
  //#endregion

  //#region actions
  async function callGrobid() {
    let restext = localStorage.getItem('xmlstr_'+filename);
    if(restext){
      renderWebView(restext);
    }
    else{
      var pdfload = pdfjs.getDocument(fileLoc);
      pdfload.promise.then(async function (pdf) {
        pdf.getData().then(async function (pdfraw) {
          let blob = new Blob([pdfraw], { type: "application/pdf" });
          let url = grobidBaseUrl + '/api/processFulltextDocument';
          let formdata = new FormData();
          formdata.append("consolidateHeader", 1);
          formdata.append("includeRawAffiliations", 1);
          formdata.append("includeRawCitations", 1);
          formdata.append("segmentSentences", 1);
          formdata.append("teiCoordinates", "ref");
          formdata.append("input", blob);
  
          try {
            const response = await fetch(url, {
              method: 'POST', mode: 'cors', cache: 'no-cache', redirect: 'follow', referrerPolicy: 'no-referrer', body: formdata
            });
            processGrobidResult(response);
          }
          catch (e) {
            console.log("grobidcallexception", e);
          }
        });
      });
    }
  }
  async function processGrobidResult(result) {
    let restext = await result.text();//kml
    localStorage.setItem('xmlstr_'+filename, restext);
    renderWebView(restext);
  }
  async function renderWebView(restext){
    var xslStylesheet;
    var xsltProcessor = new XSLTProcessor();

    var myXMLHTTPRequest = new XMLHttpRequest();
    myXMLHTTPRequest.open("GET", "/paper.xsl", false);
    myXMLHTTPRequest.send(null);

    xslStylesheet = myXMLHTTPRequest.responseXML;
    xsltProcessor.importStylesheet(xslStylesheet);

    var myXMLHTTPRequest2 = new XMLHttpRequest();
    myXMLHTTPRequest2.open("GET", "/paper.sef.json", false);
    myXMLHTTPRequest2.send(null);

    const jsonstyle = await fetch("/paper.sef.json");
    const jsons = await jsonstyle.json();

    if(window.SaxonJS){
      window.SaxonJS.transform({
          stylesheetText: JSON.stringify(jsons),
          sourceText: restext,
          destination: "serialized"
      }, "async")
      .then (async output => {
          let reshtml = await new window.DOMParser().parseFromString(output.principalResult, "text/html");
          let html = reshtml.body.children[0];

          let ss = html.querySelectorAll("s");
          ss.forEach(s=>{
            let span = document.createElement("span");
            span.innerHTML = s.innerHTML;
            s.insertAdjacentElement('afterend',span);
            s.remove();
          });

          let refs = html.querySelectorAll("ref[type='bibr']");
          refs.forEach(ref=>{
            if(ref.hasAttribute("target")){
              let refdomid = "bibl-"+ref.getAttribute("target").replace("#","");
              let a = document.createElement("a");
              a.classList.add("refLink");
              a.setAttribute("data-targetref", refdomid);
              a.innerHTML = ref.innerHTML;
              a.onclick=handlePopoverOpen;
              ref.insertAdjacentElement('afterend',a);
              ref.remove();
            }
          });

          let refItems = html.querySelectorAll(".refItem");
          refItems.forEach(refItem=>{
            let a = document.createElement("a");
            a.classList.add("refQuickSearchLink");
            a.rel = "noreferrer";
            a.target = "_blank";
            a.href = "http://www.google.com/search?btnI&q="+encodeURIComponent(refItem.innerHTML);
            a.innerHTML = "⌕";
            refItem.appendChild(a);
          });

          let keywordsList = html.querySelector(".keywords");
          if(keywordsList.childElementCount <= 0){
            let keywordsArea = html.querySelector(".keywordsArea");
            if(keywordsArea) keywordsArea.remove();
          }
  
          let webviewwrapper = document.getElementById("webViewWrapper");
          if(webviewwrapper){
            webviewwrapper.textContent = "";
            webviewwrapper.appendChild(html);
          }
      })
    }
    else{
      console.log("SaxonJselse", window.SaxonJS);
    }
  }
  function extractText(numPages) {
    // var pdfload = pdfjs.getDocument(fileLoc);
    // return pdfload.promise.then(function (pdf) { // get all pages text
    //   var countPromises = []; // collecting all page promises
    //   var textData = [];
    //   for (var j = 1; j <= numPages; j++) {
    //     var page = pdf.getPage(j);
    //     (function (pagenum) {
    //       countPromises.push(page.then(function (page) { // add page promise
    //         var textContent = page.getTextContent();
    //         return textContent.then(function (text) { // return content promise
    //           textData[pagenum] = text;
    //           return text.items.map(function (s) { return s.str; }).join(''); // value page text 
    //         });
    //       }));
    //     })(j);
    //   }
    //   // Wait for all pages and join text
    //   return Promise.all(countPromises).then(function (texts) {
    //     setTextData(textData);
    //     return texts.join('');
    //   });
    // });
  }
  function showLabelSelector(rect, onchoose) {
    labelSelectorOpen.current = true;
    let labelSelector = document.getElementById("labelSelector");
    let stage = document.getElementsByClassName("pageStage");
    let stagerect = stage[0].getBoundingClientRect();
    labelSelector.style.display = "flex";
    let top = rect.y + stagerect.y + rect.height;
    let left = rect.x + stagerect.x;
    labelSelector.style.top = top + "px";
    labelSelector.style.left = left + "px";
    setTimeout(_ => {
      let labelrect = labelSelector.getBoundingClientRect();
      if (!isElementInViewport(labelSelector) && isElementPartiallyInViewport(labelSelector)) {
        top = rect.y - labelrect.height + stagerect.y;
        labelSelector.style.top = top + "px";
      }
    }, 200)

    // let observer = new IntersectionObserver(_=>{
    //   console.log("label selector goes out");
    // }, {
    //   rootMargin: '0px',
    //   threshold: 1.0
    // });
    // observer.observe(labelSelector);

    let labels = document.getElementsByClassName("labelOptions");
    let cancelButton = labelSelector.querySelector("button");
    cancelButton.onclick = e => {
      onchoose(null);
    }
    for (let label of labels) {
      label.onclick = function (e) {
        onchoose(e.target.innerHTML);
        hideLabelSelector();
      }
    }
  }
  function hideLabelSelector() {
    labelSelectorOpen.current = false;
    let labelSelector = document.getElementById("labelSelector");
    labelSelector.style.display = "none";
  }
  function spawnNewRectangle() {
    setNewRectangle([]);
    setRectangles(rectangles);

    let annotationsOfAllPages = JSON.parse(JSON.stringify(rectangles));
    let annotationsOfActivePage = annotationsOfAllPages[activePage];
    annotationsOfActivePage.forEach(annot => {
      annot.width = annot.width * 1000 / pageRef.current.width;
      annot.x = annot.x * 1000 / pageRef.current.width;
      annot.height = annot.height * (1000 * (origSize.height / origSize.width)) / pageRef.current.height;
      annot.y = annot.y * (1000 * (origSize.height / origSize.width)) / pageRef.current.height;
    });

    let storedrect = JSON.parse(localStorage.getItem(filename));
    if (storedrect) {
      storedrect[activePage] = annotationsOfActivePage;
      localStorage.setItem(filename, JSON.stringify(storedrect));
    }
    else {
      localStorage.setItem(filename, JSON.stringify(annotationsOfAllPages));
    }

  }
  function updateRectIds() {
    for (let i in rectangles[activePage]) {
      let rect = rectangles[activePage][i];
      rect.key = i;
      rect.id = "rect" + i;
    }
  }
  function setCaptionTarget(targetid) {
    rectangles[activePage][rectangles[activePage].length - 1].captionTarget = "page" + activePage + ":" + targetid;
    setCaptionTargetSelectionMode(false);
    setRectangles(rectangles);
    let warnbar = document.getElementById("warnBar");
    warnbar.innerHTML = "";
    warnbar.style.display = "none";
  }
  function downloadBase64File(data, fileName) {
    const downloadLink = document.createElement("a");
    downloadLink.href = data;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  function downloadObjectAsJson(exportObj, exportName) {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }
  //#endregion

  //#region helpers
  function checkDeselect(e) {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  }
  function isElementPartiallyInViewport(el) {
    var rect = el.getBoundingClientRect();
    // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
    var windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    var windowWidth = (window.innerWidth || document.documentElement.clientWidth);

    // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
    var vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
    var horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

    return (vertInView && horInView);
  }
  function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    var windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    var windowWidth = (window.innerWidth || document.documentElement.clientWidth);

    return (
      (rect.left >= 0)
      && (rect.top >= 0)
      && ((rect.left + rect.width) <= windowWidth)
      && ((rect.top + rect.height) <= windowHeight)
    );
  }
  function setAndAdjustStageSizeByZoomLevel(newzoomlevel) {
    if (newzoomlevel > 0) {
      setZoomLevel(newzoomlevel);
      stageSize.width = defaultWidth * newzoomlevel / 100;
      stageSize.height *= newzoomlevel / 100;
      setStageSize(stageSize);
    }
  }
  //#endregion

  const labelOptions = [
    "Paragraph",
    "PartialParagraph",
    "Figure",
    "Table",
    "Caption",
    "Title",
    "Authors",
    "Abstract",
    "Keywords",
    "Code",
    "Formula",
    "References",
    "List",
    "Misc",
    "Heading 1",
    "Heading 2",
    "Heading 3",
    "Heading 4"
  ];
  const labelOptionsItem = [];
  for (const [index, value] of labelOptions.entries()) {
    labelOptionsItem.push(<li key={index} className="labelOptions">{value}</li>)
  }

  const zoomOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 150, 175, 200, 400];
  const zoomOptionsItem = [];
  for (const [index, value] of zoomOptions.entries()) {
    zoomOptionsItem.push(<option key={index} className="zoomOptions" value={value}>{value} {isNaN(value) ? "" : "%"}</option>)
  }
  if (!zoomOptions.includes(zoomLevel)) {
    zoomOptionsItem.push(<option key={zoomOptions.length} className="zoomOptions" value={zoomLevel}>{zoomLevel} {isNaN(zoomLevel) ? "" : "%"}</option>)
  }

  const rectanglesToDraw = [...(rectangles[activePage] || []), ...newRectangle];

  // let htmldata = {};
  // // textData[1].items[0].str
  // if (textData.length > 0 && rectangles.length > 0 && viewport && viewport.transform) {
  //   textData.forEach((pageTexts, page) => {
  //     let labels = rectangles[page];
  //     labels.forEach((label, labelid) => {
  //       pageTexts.items.forEach(pageText => {
  //         // let objTransformed = pdfjs.Util.transform(viewport.transform, pageText.transform);
  //         // need to read pdf coordinate system thoroughly and pdfjs code which transform it on its textLayer
  //         // [
  //         //   29.301960784313724, // stageWidth/origWidth * pageText.height > becomes font size
  //         //   0, // still myterious
  //         //   0, // still myterious
  //         //   -29.301960784313724, // stageWidth/origWidth * pageText.height * -1 ??
  //         //   131.56192810457517, // ?? > ended up as left value in html
  //         //   149.23104575163393 // still myterious
  //         // ]
  //       });
  //       // get all textdata that intersect with the label
  //       // combine them all based on its coordinate to see which one goes before the others
  //       //
  //     });
  //   });
  //   if (textData[1].items && textData[1].items.length > 0) {
  //     htmldata.title = textData[1].items[0].str;
  //   }
  // }

  function toggleViewMode(){
    setWebViewMode(!webViewMode);
    callGrobid();
  }

  let shapeColors = {
    fill: "rgba(255, 0, 0, 0.5)",
    stroke: "red"
  };
  if (captionTargetSelectionMode) {
    shapeColors = {
      fill: "rgba(0, 255, 0, 0.5)",
      stroke: "green"
    }
  }

  let mainView;
  if (webViewMode) {
    mainView = <div id='webViewWrapper'></div>;
  } else {
    mainView = (
      <>
      <div id="warnBar"></div>
        <div className="docViewerArea">
          <Document file={fileLoc} onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              width={stageSize.width}
              key={`page_` + activePage}
              pageNumber={activePage}
              renderAnnotationLayer={false}
              canvasRef={pageRef}
              onLoadSuccess={onPageLoadSuccess}
            >
              <Stage className="pageStage" id="pageStage"
                width={stageSize.width}
                height={stageSize.width * origSize.height / origSize.width}
                // height={pageRef.current ? pageRef.current.height : 0}
                onMouseDown={stageMouseDown}
                onMouseMove={stageMouseMove}
                onMouseUp={stageMouseUp}
              >
                <Layer>
                  {rectanglesToDraw.map((rect, i) => (
                    <Rectangle
                      id={'rect' + i}
                      fileName={filename}
                      key={i}
                      keyId={i}
                      activePage={activePage}
                      shapeProps={rect}
                      shapeColors={shapeColors}
                      zoomLevel={zoomLevel}
                      isSelected={rect.id === selectedId}
                      onSelect={() => {
                        if (captionTargetSelectionMode) {
                          setCaptionTarget(rect.id);
                        }
                        else {
                          selectShape(rect.id);
                        }
                      }}
                      onChange={(newAttrs) => {
                        rectangles[activePage][i] = newAttrs;
                        // rects[i] = newAttrs;
                        spawnNewRectangle();
                      }}
                    />
                  ))}
                </Layer>
              </Stage>
            </Page>
          </Document>

        </div>
        </>
    );
  }

  const open = Boolean(popOverAnchorEl);

  return (
    <div className="App">
      <div id="labelSelector">
        <h3>What is it?</h3>
        <ul>{labelOptionsItem}</ul>
        <button className="labelCancelButton">&#10006;</button>
      </div>
      <div id="tempCanvas" />
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h6" className={classes.title} align="left">PDFLabeller</Typography>

          {userName && (
            <div className="userNameMarker">
              <p>Signed as {userName}</p>
            </div>
          )}
          <Button color="inherit" onClick={logout} className="downloadButton" startIcon={<ExitToAppIcon />}>Logout</Button>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
      <div className="toolbar">
        <div className="toolbarLeft">
          <FormGroup>
            <FormControlLabel
              control={<Switch  checked={webViewMode} onChange={toggleViewMode} />}
              label="WebView"
            />
          </FormGroup>
        </div>
        <div className="toolbarRight">
          <Button color="inherit" onClick={onSaveButtonClicked} align="left" className="downloadButton" startIcon={<SaveIcon />}>Download images</Button>
          <Button color="inherit" onClick={onDownloadAnnotationButtonClicked} className="downloadButton" startIcon={<SaveIcon />}>Download annotations</Button>
        </div>

      </div>
      <div className="main">
        {mainView}
        {debugMode && (<DebugBox
          annotations={rectangles}
          numPages={numPages}
          initLabels={initLabels}
        />)}

        

      </div>
      {!webViewMode && (
      <div className="toolbar">
        <div className="toolbarLeft">
          <Button color="inherit" onClick={onPrevButtonClicked} className="prevButton" startIcon={<NavigateBeforeIcon />}>Previous Page</Button>
          <div className="pageInfo">
            <input className="activePageInput" type="number" value={activePage} onChange={e => {
              let val = parseInt(e.target.value);
              if (val > 0 && val <= numPages) {
                setActivePage(parseInt(e.target.value));
              }
            }} /> of {numPages}
          </div>
          <Button color="inherit" onClick={onNextButtonClicked} className="nextButton" endIcon={<NavigateNextIcon />}>Next Page</Button>
        </div>
        <div className="toolbarRight">
          <Button color="inherit" onClick={e => { setAndAdjustStageSizeByZoomLevel(zoomLevel - 10) }} className="nextButton" endIcon={<ZoomOutIcon />}>ZoomOut</Button>
          {/* <input className="zoomLevelInput" type="number" value={zoomLevel} onChange={e => { setAndAdjustStageSizeByZoomLevel(parseInt(e.target.value)); }} /> */}
          <div className="zoomSelector">
            <select value={zoomLevel} onChange={e => {
              let val = parseInt(e.target.value);
              setAndAdjustStageSizeByZoomLevel(val);
            }}>
              {zoomOptionsItem}
            </select>
            {/* <input className="zoomLevelInput" type="number" value={zoomLevel} onChange={e => { 
                let val = parseInt(e.target.value);
              setAndAdjustStageSizeByZoomLevel(val);
            }} /> */}
          </div>
          <Button color="inherit" onClick={e => { setAndAdjustStageSizeByZoomLevel(zoomLevel + 10) }} className="nextButton" endIcon={<ZoomInIcon />}>ZoomIn</Button>
        </div>
      </div>
      )}
      <Popover
      id="mouse-over-popover"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      anchorEl={popOverAnchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
        <div className="refItem">
          {popOverContent}
          <a className="refQuickSearchLink" rel="noreferrer" target="_blank" href={"http://www.google.com/search?btnI&q="+encodeURIComponent(popOverContent)}>⌕</a>
        </div>
      </Popover>
    </div>
  );
}

export default Labeller;
