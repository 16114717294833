import './Home.css';
import React from 'react';
import { Link } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

const Home = () => {
//   const history = useHistory();
  const { authState } = useOktaAuth();

  if (authState.isPending) return null;
  

//   const login = async () => history.push('/login');
  
//   const logout = async () => oktaAuth.signOut();

//   const button = authState.isAuthenticated ? 
//     <button onClick={logout}>Logout</button> :
//     <button onClick={login}>Login</button>;

  return (
    <div className="homeWrapper">
      <Link className="homeLink" to='/Labeller'>Start labelling PDFs</Link><br/>
    </div>
  ); 
};
export default Home;