
import ReactJson from 'react-json-view';
import { Button } from '@material-ui/core';

export function DebugBox({ annotations, initLabels, numPages, generatedHTML }) {
    return (
        <div className="debugBox">
            <div className="debugBoxToolbar">
                <Button color="inherit" onClick={e => { initLabels(numPages, true) }}>Clear labels</Button>
            </div>
            <div className="debugBoxMain">
                <div className="debugBoxMainLeft">
                    <ReactJson src={annotations} collapsed={false} displayDataTypes={false} indentWidth={2} />
                </div>
                <div className="debugBoxMainRight" id="iframearea">
                    <iframe title="iframearea" className="debugBoxIFrame" src={'data:text/xml;charset=utf-8,' + encodeURIComponent(generatedHTML)} />
                </div>
            </div>
        </div>
    )
}